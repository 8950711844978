<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">                
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            ref="name"
                            v-model="sedeForm.name" 
                            label="Nombre" 
                            outlined 
                            :rules="[() => !!sedeForm.name || 'Es necesario ingresar un nombre']"
                            hide-details="auto"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="company_id"
                            v-model="sedeForm.company_id"
                            :items="companies"
                            label="Seleccione empresa"                            
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            :rules="[() => !!sedeForm.company_id || 'Es necesario seleccionar una empresa']"
                            hide-details="auto"
                            required
                        ></v-autocomplete>
                    </v-col>
                </v-row>                   
            </template>                    
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'CreateUpdate',
    data() {
        return {
            drawer: false,
            titleForm: null,
            action: null,
            sedeForm: {
                id: null,
                name: null,
                company_id: null
            }
        }
    },
    computed: {
        ...mapState('companies', [
            'companies'
        ])
    },
    methods: {
        ...mapActions('companies', [
            'getCompanies'
        ]),
        ...mapActions('sedes', [
            'store',
            'update'
        ]),
        showForm(title, action, sede = null) {
            this.clearData();
            this.titleForm = title
            this.action = action
            if (sede) {
                this.setSede(sede)
            }
            this.drawer = !this.drawer
        },
        setSede(sede) {
            this.sedeForm.id = sede.id
            this.sedeForm.name = sede.name
            this.sedeForm.company_id = sede.company_id
        },
        clearData() {
            this.titleForm = null
            this.action = null
            this.sedeForm.id = null
            this.sedeForm.name = null
            this.sedeForm.company_id = null
            this.clearErrors();
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        customText(item){
            let text = item.name
            
            return text
        },
        async handleStore() {
            if (!this.existErrors()) {
                await this.store(this.sedeForm);
                this.closeForm();
            }            
        },
        async handleUpdate() {
            if (!this.existErrors()) {
                await this.update(this.sedeForm);
                this.closeForm();
            }            
        },
        handleCancel() {
            this.closeForm()
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.sedeForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.sedeForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.sedeForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    },
    mounted() {
        this.getCompanies()
    }
}
</script>

<style scoped>

</style>